import { createApp, nextTick } from "vue";
import App from "./App.vue";
import "./assets/styles/main.css";
import router from "./router";
import store from "./store";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import SpinnerMessage from "@/components/utilities/SpinnerMessage";
import CancelButton from "@/components/buttons/CancelButton";
import BackButton from "@/components/buttons/BackButton";
import TableKey from "@/components/utilities/TableKey";
import PageHeader from "@/components/PageHeader";
import HelpersMixin from "@/helpers/HelpersMixin";
import RTable from "./components/RTable.vue";
import RTableHeaderRow from "./components/RTableHeaderRow.vue";
import RTableRow from "./components/RTableRow.vue";
import RTableCell from "./components/RTableCell.vue";

import i18n from "./i18n";
import * as Sentry from "@sentry/vue";
import { defineRule } from "vee-validate";
import OpusPlugin from "./OpusPlugin.js"
import { useAdminsListenToBroadcasts } from "@/composables/broadcasting";

defineRule("required", (value) => {
  if (!value) {
    return "This is required";
  }

  return true;
});

defineRule("max", (value, [max]) => {
  if (typeof value === "number") {
    if (value > max) {
      return `Should be less than or equal to ${max}`;
    }
  }

  if (value && value.length > max) {
    return `Should be fewer than ${max} characters`;
  }

  return true;
});

defineRule("min", (value, [min]) => {
  //if the value is a number check if it is less than the min
  if (typeof value === "number") {
    if (value < min) {
      return `Should be at least ${min}`;
    }
  }

  if (value && value.length < min) {
    return `Should be at least ${min} characters`;
  }

  return true;
});

defineRule("email", (value) => {
  // if the field is not a valid email .education is the longest TLD we've had to allow for so far
  if (!/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(value)) {
    return "This field must be a valid email";
  }

  // All is good
  return true;
});

defineRule("confirmation", (value, [other]) => {
  if (!value && !other) {
    return "Please provide a password";
  }

  if (value !== other) {
    return "Please check your password, your confimation does not match it";
  }

  return true;
});

defineRule("password", (value) => {
  let strictRules = [
    { name: "lowercase", rule: /[a-z]/ },
    { name: "uppercase", rule: /[A-Z]/ },
    { name: "numbers", rule: /[0-9]/ },
    { name: "symbols", rule: /[@£!+]/ }
  ];

  // Iterate over each rule, checking to see if the password works.
  let fitsRules = 0;
  strictRules.forEach(function (rule) {
    // Run the regex on the password and return whether
    // or not it matches.
    fitsRules += rule.rule.test(value) ? 1 : 0;
  });

  // If it doesn't fit the rules, generate a new one (recursion).
  if (fitsRules < 3) {
    return `Passwords must be a minimum of 8 characters long, 
    and contain 1 or more characters from at least 3 of these 4
    sets: lowercase, uppercase, numbers and these symbols: @£!+`;
  }

  return true;
});

//load base components
const requireComponent = require.context("./components", false, /Base[A-Z]\w+\.(vue|js)$/);

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

//begin creation of our lms app
const lms = createApp(App).directive("click-outside", clickOutside);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app: lms,
    dsn: "https://1acabadd88af4e46831518ecd9d1bedd@o1057486.ingest.sentry.io/6044302",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "staging-lms.opuspharmserve.com",
          "lms.opuspharmserve.com"
        ]
      }),
      // turned of replay because it causes problems with unsafe inline blob for CSP
      //new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // turned of replay because it causes problems with unsafe inline blob for CSP
    //replaysSessionSampleRate: 0.05,
    //replaysOnErrorSampleRate: 1.0,
    release: "production"
  });
} else {
  lms.config.errorHandler = function (err, vm, info) {
    console.warn("The following error was caught by the global error handler");
    console.error(err);
    console.log(vm);
    console.log(info);
  };
}

lms.use(i18n).mixin(HelpersMixin).use(store).use(router).use(OpusPlugin);

//register base components
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1")));

  lms.component(componentName, componentConfig.default || componentConfig);
});

lms.component("SpinnerMessage", SpinnerMessage);
lms.component("CancelButton", CancelButton);
lms.component("BackButton", BackButton);
lms.component("TableKey", TableKey);
lms.component("PageHeader", PageHeader);
lms.component("RTable", RTable);
lms.component("RTableHeaderRow", RTableHeaderRow);
lms.component("RTableRow", RTableRow);
lms.component("RTableCell", RTableCell);


//icons
import IconCourseAlt from "~icons/heroicons-outline/academic-cap";
import IconCourse from "~icons/heroicons-solid/academic-cap";
import IconEdit from "~icons/heroicons-solid/pencil";
import IconArchive from "~icons/heroicons-solid/archive";
import IconArchiveView from "~icons/mdi/archive-view";
import IconUser from "~icons/heroicons-solid/user";
import IconUsers from "~icons/heroicons-solid/users";
import IconUserGroup from "~icons/heroicons-solid/user-group";
import IconArrowUp from "~icons/heroicons-solid/arrow-narrow-up";
import IconArrowDown from "~icons/heroicons-solid/arrow-narrow-down";
import IconArrowLeft from "~icons/heroicons-solid/arrow-narrow-left";
import IconUpDown from "~icons/lucide/chevrons-up-down";
import IconDown from "~icons/lucide/chevron-down";
import IconUp from "~icons/lucide/chevron-up";
import IconPlus from "~icons/heroicons-solid/plus-sm";
import IconClipboard from "~icons/heroicons-solid/clipboard-list";
import IconPlusCircle from "~icons/heroicons-solid/plus-circle";
import IconCog from "~icons/heroicons-solid/cog";
import IconInfo from "~icons/heroicons-solid/information-circle";
import IconOrg from "~icons/heroicons-solid/office-building";
import IconTrash from "~icons/heroicons-solid/trash";
import IconQuestionCircle from "~icons/heroicons-solid/question-mark-circle";
import IconKey from "~icons/bi/key-fill";
import IconService from "~icons/bx/building-house";
import IconLicence from "~icons/fa/drivers-license";
import IconLogout from "~icons/heroicons-outline/logout";
import IconSuper from "~icons/noto/man-superhero-light-skin-tone";
import IconPassed from "~icons/heroicons-solid/shield-check";
import IconFailed from "~icons/heroicons-solid/exclamation-circle";
import IconNotStarted from "~icons/heroicons-solid/stop";
import IconStarted from "~icons/heroicons-solid/play";
import IconTrainer from "~icons/carbon/user-avatar-filled";
import IconReport from "~icons/et/document";
import IconEmail from "~icons/fontisto/email";
import IconPrinter from "~icons/dashicons/printer";
import IconReset from "~icons/bx/reset";
import IconDownload from "~icons/material-symbols/download-rounded";
import IconDashboard from "~icons/clarity/dashboard-line";
import IconTotal from "~icons/tabler/sum";
import IconLog from "~icons/octicon/log-24";
import IconQuill from "~icons/game-icons/quill-ink";
import IconManager from "~icons/fluent-mdl2/manager-self-service"
import IconActions from "~icons/eos-icons/action-chains-outlined"
import IconCamera from "~icons/bx/camera"
import IconHide from "~icons/mdi/hide"
import IconShow from "~icons/mdi/show"
import IconExclamation from "~icons/zondicons/exclamation-outline"
import IconFaceToFace from "~icons/healthicons/i-training-class"
import IconLocation from "~icons/carbon/location"
import IconThreeDots from "~icons/ph/dots-three-outline-fill"
import IconSearch from "~icons/ion/search-outline"
import IconTrainingCourses from "~icons/game-icons/video-conference"
import IconCheckbox from "~icons/mdi/checkbox-outline"
import IconPaperBased from "~icons/et/documents"
import IconAdmin from "~icons/ri/admin-fill"
import IconClock from "~icons/ph/clock-light"
// import IconLocation from "~icons/system-uicons/location"
import IconInfoOutline from "~icons/fluent/info-20-regular"
import IconHome from "~icons/ant-design/home-outlined"
import IconPersonCircle from "~icons/streamline/interface-user-circle-circle-geometric-human-person-single-user"
import IconHamburger from "~icons/quill/hamburger"
import IconCross from "~icons/akar-icons/cross"
import IconSend from "~icons/bxs/send"

import IconTickCircle from "~icons/mdi/tick-circle"
import IconCrossCircle from "~icons/gridicons/cross-circle"
import IconCalendar from "~icons/ion/calendar-outline"
import IconProject from "~icons/la/project-diagram"
import IconBell from '~icons/heroicons/bell';
import IconBellActive from "~icons/heroicons/bell-alert"
import IconUk from "~icons/material-symbols-light/globe-uk-sharp"
import IconReservation from "~icons/fluent-mdl2/reservation-orders"
import IconList from "~icons/line-md/list-3"
import IconDrag from "~icons/radix-icons/drag-handle-dots-2"
import IconFeedback from "~icons/fluent-mdl2/feedback"

lms.component("IconCourseAlt", IconCourseAlt);
lms.component("IconCourse", IconCourse);
lms.component("IconEdit", IconEdit);
lms.component("IconArchive", IconArchive);
lms.component("IconArchiveView", IconArchiveView);
lms.component("IconUser", IconUser);
lms.component("IconUsers", IconUsers);
lms.component("IconUserGroup", IconUserGroup);
lms.component("IconArrowUp", IconArrowUp);
lms.component("IconArrowDown", IconArrowDown);
lms.component("IconArrowLeft", IconArrowLeft);
lms.component("IconUpDown", IconUpDown);
lms.component("IconDown", IconDown);
lms.component("IconUp", IconUp);
lms.component("IconPlus", IconPlus);
lms.component("IconClipboard", IconClipboard);
lms.component("IconPlusCircle", IconPlusCircle);
lms.component("IconCog", IconCog);
lms.component("IconInfo", IconInfo);
lms.component("IconOrg", IconOrg);
lms.component("IconTrash", IconTrash);
lms.component("IconQuestionCircle", IconQuestionCircle);
lms.component("IconKey", IconKey);
lms.component("IconService", IconService);
lms.component("IconLicence", IconLicence);
lms.component("IconLogout", IconLogout);
lms.component("IconSuper", IconSuper);
lms.component("IconPassed", IconPassed);
lms.component("IconFailed", IconFailed);
lms.component("IconNotStarted", IconNotStarted);
lms.component("IconStarted", IconStarted);
lms.component("IconTrainer", IconTrainer);
lms.component("IconReport", IconReport);
lms.component("IconEmail", IconEmail);
lms.component("IconPrinter", IconPrinter);
lms.component("IconReset", IconReset);
lms.component("IconDownload", IconDownload);
lms.component("IconDashboard", IconDashboard);
lms.component("IconTotal", IconTotal);
lms.component("IconLog", IconLog);
lms.component("IconQuill", IconQuill);
lms.component("IconManager", IconManager);
lms.component("IconActions", IconActions);
lms.component("IconCamera", IconCamera);
lms.component("IconHide", IconHide);
lms.component("IconShow", IconShow);
lms.component("IconExclamation", IconExclamation);
lms.component("IconFaceToFace", IconFaceToFace);
lms.component("IconSearch", IconSearch);
lms.component("IconTrainingCourses", IconTrainingCourses);
lms.component("IconCheckbox", IconCheckbox);
lms.component("IconPaperBased", IconPaperBased);
lms.component("IconAdmin", IconAdmin);
lms.component("IconClock", IconClock);
lms.component("IconLocation", IconLocation);
lms.component("IconThreeDots", IconThreeDots);
lms.component("IconInfoOutline", IconInfoOutline);
lms.component("IconHome", IconHome);
lms.component("IconPersonCircle", IconPersonCircle);
lms.component("IconHamburger", IconHamburger);
lms.component("IconCross", IconCross);
lms.component("IconSend", IconSend);
lms.component("IconTickCircle", IconTickCircle);
lms.component("IconCrossCircle", IconCrossCircle);
lms.component("IconCalendar", IconCalendar);
lms.component("IconProject", IconProject);
lms.component("IconBell", IconBell);
lms.component("IconBellActive", IconBellActive);
lms.component("IconUk", IconUk);
lms.component("IconReservation", IconReservation);
lms.component("IconList", IconList);
lms.component("IconDrag", IconDrag);
lms.component("IconFeedback", IconFeedback);

//mount our lms app
router.isReady().then(() => {
  lms.mount("#app");
  nextTick(() => {
    if (store.state.user && store.state.user.id) {
      //we need this hear for a page refresh when already logged in
      useAdminsListenToBroadcasts(store.state.user.id);
      //we also do this upon successful login in the loginForm component
    }
  });
});
