import auth from "@/middleware/auth.js";
import coursesChildren from "@/router/site_admin/coursesChildren";
import bookingSystemChildren from "@/router/site_admin/bookingSystemChildren";
import groupsChildren from "@/router/site_admin/groupsChildren";
import usersChildren from "@/router/site_admin/usersChildren";
import documentsChildren from "@/router/site_admin/documentsChildren";
import store from "@/store";

const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "SiteAdminDefaultChild",
    redirect: {
      name: "SiteAdminCourses"
    }
  },
  {
    path: "courses",
    name: "SiteAdminCoursesLayout",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [...coursesChildren]
  },
  {
    name: "SiteAdminBookingSystem",
    path: "bookings",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [...bookingSystemChildren]
  },
  { 
    path: "linked-abstract-courses",
    name: "SiteAdminLinkedAbstractCourses",
    component: () =>
      import(
        /* webpackChunkName: "siteAdmin" */ "@/views/site_admin/linked_abstract_courses/LinkedAbstractCourses.vue"
      ),
    meta: {
      middleware: auth
    },
  },
  {
    path: "new-linked-abstract-courses",
    name: "SiteAdminNewLinkedAbstractCourses",
    component: () =>
      import(
        /* webpackChunkName: "siteAdmin" */ "@/views/site_admin/linked_abstract_courses/NewLinkedAbstractCourses.vue"
      ),
    meta: {
      middleware: auth
    },
  },
  {
    path: "groups",
    name: "SiteAdminGroupsLayout",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/groups/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [...groupsChildren]
  },
  {
    path: "documents",
    name: "SiteAdminDocumentsLayout",
    component: () => import(/* webpackChunkName: "commonAdmin" */ "@/views/common/documents/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [...documentsChildren]
  },
  {
    path: "users",
    name: "SiteAdminUsersLayout",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/users/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [...usersChildren]
  },
  {
    path: "message-preview-page",
    name: "SiteAdminMessagePreviews",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/previews/MessagePreviews.vue"),
    meta: {
      middleware: auth
    },
  },
  {
    path: "message-preview-page/:messagePreviewCode",
    name: "SiteAdminMessagePreviewsClass",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/previews/MessagePreviews.vue"),
    meta: {
      middleware: auth
    },
    props: true
  },
  {
    path: "admin-audits",
    name: "SiteAdminAdminAudits",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/admin_audits/AdminAudits.vue"),
    meta: {
      middleware: auth
    },
  },
  {
    path: "developer-settings",
    name: "SiteAdminDeveloperSettings",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/DeveloperSettings.vue"),
    meta: {
      middleware: auth
    },
  },
  {
    path: "super-admins",
    name: "SiteAdminSuperAdmins",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/SuperAdmins.vue"),
    meta: {
      middleware: auth
    },
  },
  {
    path: "super-admin-new",
    name: "SiteAdminSuperAdminNew",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/SuperAdminNew.vue"),
    meta: {
      middleware: auth
    },
  },
  {
    path: "super-admin/confirmation-token/:tokenId/:tokenKey",
    name: "SiteAdminSuperAdminConfirmation",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/SuperAdminConfirmation.vue"),
    meta: {
      middleware: auth
    },
    props: true
  },
  {
    path: "trainers",
    name: "SiteAdminTrainers",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/trainers/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [
      {
        name: "SiteAdminTrainersLayout",
        path: "",
        redirect: {
          name: "SiteAdminTrainersIndex"
        }
      },
      {
        path: "index",
        name: "SiteAdminTrainersIndex",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/trainers/Trainers.vue"),
        meta: {
          middleware: auth
        }
      },
      {
        path: "upcoming-pharmacist-led-courses",
        name: "SiteAdminTrainersVirtualGroups",
        component: () =>
          import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/trainers/TrainersVirtualGroups.vue"),
        meta: {
          middleware: auth
        }
      },
      {
        path: "new-trainer",
        name: "SiteAdminNewTrainer",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/trainers/NewTrainer.vue"),
        meta: {
          middleware: auth
        }
      },
      {
        path: "edit-trainer/:id",
        name: "SiteAdminEditTrainer",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/trainers/EditTrainer.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let id = parseUrlParamToInt(route, "id");

          return {
            id
          };
        }
      },
      {
        path: "edit-trainer-general-availability/:id",
        name: "SiteAdminEditTrainerGeneralAvailabilityCalendar",
        component: () => import(
          /* webpackChunkName: "siteAdmin" */ "@/views/site_admin/trainers/EditGeneralAvailabilityCalendar.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let id = parseUrlParamToInt(route, "id");

          return {
            id
          };
        }
      },
      {
        path: "view-trainer/:id",
        name: "SiteAdminViewTrainer",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/trainers/ViewTrainer.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let id = parseUrlParamToInt(route, "id");

          return {
            id
          };
        }
      }
    ]
  },
  {
    path: "reports",
    name: "SiteAdminReportsLayout",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/reports/Layout.vue"),
    meta: {
      middleware: auth
    },
    children: [
      {
        name: "SiteAdminReports",
        path: "",
        redirect: {
          name: "SiteAdminReportsDashboard"
        }
      },
      {
        path: "dashboard",
        name: "SiteAdminReportsDashboard",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/reports/Dashboard.vue"),
        meta: {
          middleware: auth
        }
      },
      {
        path: "certificate-expiries",
        name: "SiteAdminReportsCertificateExpiries",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/common/reports/CertificateExpiries.vue"),
        meta: {
          middleware: auth
        },
        props: {
          level: "super_admin"
        }
      },
      {
        path: "licence-levels",
        name: "SiteAdminReportsLicenceLevels",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/reports/LowLicenceLevels.vue"),
        meta: {
          middleware: auth
        }
      },
      {
        path: "all-licence-levels",
        name: "SiteAdminReportsAllLicenceLevels",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/reports/AllLicenceLevels.vue"),
        meta: {
          middleware: auth
        }
      },
      {
        path: "virtual-groups-health/:details",
        name: "SiteAdminReportsVirtualGroupHealth",
        component: () =>
          import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/reports/VirtualGroupHealth.vue"),
        meta: {
          middleware: auth
        },
        props: (route) => {
          let details = route.params.details;

          return {
            details
          };
        }
      },
      {
        path: "results",
        name: "SiteAdminReportsCourseResults",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/common/reports/CourseResults.vue"),
        meta: {
          middleware: auth
        },
        props: {
          adminLevel: "superAdmin"
        }
      },
      {
        path: "assessments-due",
        name: "SiteAdminReportsAssessmentsDue",
        component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/common/reports/AssessmentsDue.vue"),
        meta: {
          middleware: auth
        }
      },
      {
        path: "prepare-fixes-for-broken-managers",
        name: "SiteAdminPrepareBrokenManagerFixes",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/reports/PrepareBrokenManagerFixes.vue"
          ),
        meta: {
          middleware: auth
        }
      },
      {
        path: "licence-requests",
        name: "SiteAdminGroupLicenceRequests",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/reports/GroupLicenceRequests.vue"
          ),
        meta: {
          middleware: auth
        }
      },
      {
        path: "training-records",
        name: "SiteAdminReportsGroupTrainingRecords",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/common/reports/GroupTrainingRecord.vue"
          ),
        meta: {
          middleware: auth
        }
      },
      {
        path: "average-course-feedback",
        name: "SiteAdminReportsAverageCourseFeedback",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/reports/AverageCourseFeedback.vue"
          ),
        meta: {
          middleware: auth
        }
      },
      {
        path: "average-trainer-feedback",
        name: "SiteAdminReportsAverageTrainerFeedback",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/reports/AverageTrainerFeedback.vue"
          ),
        meta: {
          middleware: auth
        }
      },
      {
        path: "duplication-overview",
        name: "SiteAdminReportsGroupUserDuplicationOverview",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/reports/GroupUserDuplicationOverview.vue"
          ),
        meta: {
          middleware: auth
        }
      },
      {
        path: "course-overview",
        name: "SiteAdminCourseOverview",
        component: () =>
          import(
            /* webpackChunkName: "siteAdmin" */
            "@/views/site_admin/reports/CourseOverview.vue"
          ),
        meta: {
          middleware: auth
        }
      }
    ]
  },
  {
    path: "venues",
    name: "SiteAdminVenues",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/venues/Venues.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "venues/new",
    name: "SiteAdminNewVenue",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/venues/NewVenue.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "venues/edit/:venueId",
    name: "SiteAdminEditVenue",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/venues/EditVenue.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let venueId = parseUrlParamToInt(route, "venueId");

      return {
        venueId
      };
    }
  },
  {
    path: "venues/exclusions/:venueId",
    name: "SiteAdminVenueExclusions",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/venues/VenueExclusions.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let venueId = parseUrlParamToInt(route, "venueId");

      return {
        venueId
      };
    }
  },
  {
    path: "locations",
    name: "SiteAdminLocations",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/locations/Locations.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "locations/new",
    name: "SiteAdminNewLocation",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/locations/NewLocation.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "locations/edit/:locationId",
    name: "SiteAdminEditLocation",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/locations/EditLocation.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let locationId = parseUrlParamToInt(route, "locationId");

      return {
        locationId
      };
    }
  }
];
