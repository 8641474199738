<script setup>
import { toRef } from "vue";

const props = defineProps({
  quantity: {
    type: [Number, String],
    required: true
  },
  singular: {
    type: String,
    required: true
  },
  plural: {
    type: String,
    required: true
  }
});

const quantityRef = toRef(props, "quantity");

//change quantityRef to number if it's a string
if (typeof quantityRef === "string") {
  quantityRef = parseInt(quantityRef);
}
</script>
<template>
  <span v-if="quantityRef === 0 || quantityRef > 1">
    {{ plural }}
  </span>
  <span v-else>
    {{ singular }}
  </span>
</template>
