import auth from "../../middleware/auth.js";
const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "SiteAdminCourses",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/Courses.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "extra-data",
    name: "SiteAdminCoursesExtraData",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/CoursesExtraData.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "refreshers",
    name: "SiteAdminRefreshers",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/Refreshers.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "new-course",
    name: "NewCourse",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/NewCourse.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "edit-course/:courseId/:justCreated?",
    name: "EditCourse",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/EditCourse.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let justCreated = route.params.justCreated ? true : false;
      let courseId = parseUrlParamToInt(route, "courseId");
      return { courseId, justCreated };
    }
  },
  {
    path: "edit-sco/:courseId/:scoId/",
    name: "EditSco",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/EditSco.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let courseId = parseUrlParamToInt(route, "courseId");
      let scoId = parseUrlParamToInt(route, "scoId");
      return { courseId, scoId };
    }
  },
  {
    path: "scos/:courseId/",
    name: "Scos",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/Scos.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let courseId = parseUrlParamToInt(route, "courseId");
      return { courseId };
    }
  },
  {
    path: "new-paper-course",
    name: "NewPaperCourse",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/NewPaperCourse.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "edit-paper-course/:paperCourseId",
    name: "EditPaperCourse",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/EditPaperCourse.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let paperCourseId = Number.parseInt(route.params.paperCourseId, 10);
      if (Number.isNaN(paperCourseId)) {
        paperCourseId = 0;
      }
      return { paperCourseId };
    }
  },
  {
    path: "new-external-course",
    name: "NewExternalCourse",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/NewExternalCourse.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "edit-external-course/:externalCourseId",
    name: "EditExternalCourse",
    component: () => import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/EditExternalCourse.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let externalCourseId = Number.parseInt(route.params.externalCourseId, 10);
      if (Number.isNaN(externalCourseId)) {
        externalCourseId = 0;
      }
      return { externalCourseId };
    }
  },
  {
    path: "external-course/:userId/:groupId(\\d+)?",
    name: "SiteAdminExternalCourseAllocation",
    component: () =>
      import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/AllocateExternalCourse.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let userId = parseUrlParamToInt(route, "userId");
      if (route.params.groupId === undefined) {
        return {  userId };
      }

      let groupId = parseUrlParamToInt(route, "groupId");
      return {  groupId, userId };
    }
  },
  {
    path: "external-course/:userId/:externalCourseUserId/edit",
    name: "SiteAdminEditExternalCourseUser",
    component: () =>
      import(/* webpackChunkName: "siteAdmin" */ "@/views/site_admin/courses/EditExternalCourseUser.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let userId = parseUrlParamToInt(route, "userId");
      let externalCourseUserId = parseUrlParamToInt(route, "externalCourseUserId");
      return {  userId, externalCourseUserId };
    }
  }
];
