import { computed } from "vue";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
dayjs.extend(advancedFormat);
dayjs.extend(utc);
import store from "@/store";

export const useHasSuperAdmin = computed(() => {
  if (!store.state.user) {
    return false;
  }

  return store.state.user.roles.find((r) => r.name === "super_admin");
});

export const useHasGroupAdmin = computed(() => {
  if (!store.state.user) {
    return false;
  }

  return store.state.user.roles.find((r) => r.name === "group_admin");
});

export const useHasSubgroupAdmin = computed(() => {
  if (!store.state.user) {
    return false;
  }

  return store.state.user.roles.find((r) => r.name === "subgroup_admin");
});

export const useHasTrainerRole = computed(() => {
  if (!store.state.user) {
    return false;
  }

  return store.state.user.roles.find((r) => r.name === "trainer");
});

export const useSubgroupAdminLevel = function (subgroupId = null) {
  // if they're not managing any service yet we'll set them at the lowest level
  if (!store.state.user || !store.state.user.subgroups_managed || store.state.user.subgroups_managed.length === 0) {
    return "LOW";
  }

  // if no subgroupId e.g. when we need to know what help to show them before they've selected a service
  if (!subgroupId) {
    let level1Count = 0;
    let level2Count = 0;
    let level3Count = 0;
    let level;
    store.state.user.subgroups_managed.forEach((sm) => {
      if (sm.group.group_setting && sm.group.group_setting.default_service_manager_level) {
        level = sm.group.group_setting.default_service_manager_level;
      } else {
        //fail to safe
        level = "LOW";
      }
      if (level === "HIGH") {
        level1Count++;
      }
      if (level === "MED") {
        level2Count++;
      }
      if (level === "LOW") {
        level3Count++;
      }
    });
    if (level1Count && !level2Count && !level3Count) {
      return "HIGH";
    }
    if (level2Count && !level1Count && !level3Count) {
      return "MED";
    }
    return "LOW";
  }

  let idx = store.state.user.subgroups_managed.findIndex((sm) => {
    return sm.id === subgroupId;
  });

  if (idx === -1) {
    return "LOW";
  }

  if (store.state.user.subgroups_managed[idx].group && store.state.user.subgroups_managed[idx].group.group_setting) {
    return store.state.user.subgroups_managed[idx].group.group_setting.default_service_manager_level;
  }

  return "LOW";
};

export const useIsSessionStorageEnabled = function () {
  try {
    const key = `__storage__test`;
    window.sessionStorage.setItem(key, null);
    window.sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const useIsLocalStorageEnabled = function () {
  try {
    const key = `__storage__test`;
    window.localStorage.setItem(key, null);
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const useFlashSuccess = function (message, durationInMs = 7000, type = "banner") {
  store.commit("setFlashMessage", {
    message: message,
    durationInMs: durationInMs,
    type: type,
    status: "success"
  });
};

export const useFlashWarning = function (message, durationInMs = 7000, type = "banner") {
  store.commit("setFlashMessage", {
    message: message,
    durationInMs: durationInMs,
    type: type,
    status: "warning"
  });
};

export const hasLaravelErrors = function (errors) {
  return errors && errors.response && errors.response.data && errors.response.data.errors;
};

export const useFlashError = function (
  message = `Something went wrong, please try again or contact technical support if the problem persists.`,
  durationInMs = 7000,
  type = "banner"
) {
  //if message is object check for laravel errors
  if (typeof message === "object" && hasLaravelErrors(message)) {
    let errorMessages = [];
    for (let key in message.response.data.errors) {
      errorMessages.push(message.response.data.errors[key][0]);
    }
    message = errorMessages.join(", ");
  }
  store.commit("setFlashMessage", {
    message: message,
    durationInMs: durationInMs,
    type: type,
    status: "error"
  });
};

export const useShortDate = function (date) {
  if (date === undefined || date === null || !dayjs(date).isValid) {
    return "N/A";
  }

  return dayjs(date).utc().format("DD-MM-YYYY");
};

export const useShortDayOfDate = function (date) {
  if (date === undefined || date === null || !dayjs(date).isValid) {
    return "N/A";
  }

  return dayjs(date).utc().format("ddd Do MMM YYYY");
};

export const useTimeRange = function(date1, date2) {
  if (date1 === undefined || date1 === null || !dayjs(date1).isValid) {
    return "N/A";
  }

  if (date2 === undefined || date2 === null || !dayjs(date2).isValid) {
    return "N/A";
  }

  return `${dayjs(date1).utc().format("HH:mm")} - ${dayjs(date2).utc().format("HH:mm")}`;
}
