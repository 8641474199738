import ApiService from "@/services/ApiService";

export default {
  namespaced: true,
  state: {
    selectedCourse: null,
    selectedAbstractCourse: null,
    selectedPaperCourse: null,
    courses: null,
    abstractCourses: null,
    paperCourses: null,
    abstractPaperCourses: null,
    currentGroup: null,
    currentSubgroup: null,
    currentVirtualGroup: null,
    virtualGroupHealth: null,
    groupVirtualGroupHealth: null,
    unreadNotificationCount: 0
  },
  mutations: {
    setSelectedCourse(state, course) {
      state.selectedCourse = course;
    },
    incrementUnreadNotificationCount(state) {
      state.unreadNotificationCount++;
    },
    decrementUnreadNotificationCount(state) {
      state.unreadNotificationCount--;
    },
    setUnreadNotificationCount(state, count) {
      state.unreadNotificationCount = count;
    },
    setSelectedAbstractCourse(state, abstractCourse) {
      state.selectedAbstractCourse = abstractCourse;
    },
    setSelectedPaperCourse(state, paperCourse) {
      state.selectedPaperCourse = paperCourse;
    },
    setSelectedAbstractPaperCourse(state, abstractPaperCourse) {
      state.selectedAbstractPaperCourse = abstractPaperCourse;
    },
    setCourses(state, courses) {
      state.courses = courses;
    },
    setAbstractCourses(state, abstractCourses) {
      state.abstractCourses = abstractCourses;
    },
    removeCourseById(state, courseId) {
      if (!state.courses) {
        return;
      }

      let courseIndex = state.courses.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(courseId, 10);
      });
      if (courseIndex !== -1) {
        state.courses.splice(courseIndex, 1);
      }
    },
    removeAbstractCourseById(state, abstractCourseId) {
      if (!state.abstractCourses) {
        return;
      }

      let abstractCourseIndex = state.abstractCourses.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(abstractCourseId, 10);
      });
      if (abstractCourseIndex !== -1) {
        state.abstractCourses.splice(abstractCourseIndex, 1);
      }
    },
    removePaperCourseById(state, paperCourseId) {
      if (!state.paperCourses) {
        return;
      }

      let paperCourseIndex = state.paperCourses.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(paperCourseId, 10);
      });
      if (paperCourseIndex !== -1) {
        state.paperCourses.splice(paperCourseIndex, 1);
      }
    },
    removeAbstractPaperCourseById(state, abstractPaperCourseId) {
      if (!state.abstractPaperCourses) {
        return;
      }

      let abstractPaperCourseIndex = state.abstractPaperCourses.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(abstractPaperCourseId, 10);
      });
      if (abstractPaperCourseIndex !== -1) {
        state.abstractPaperCourses.splice(abstractPaperCourseIndex, 1);
      }
    },
    updateCourseInCourses(state, course) {
      if (!state.courses) {
        return;
      }

      let courseIndex = state.courses.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(course.id, 10);
      });
      if (courseIndex !== -1) {
        state.courses[courseIndex] = course;
      }
    },
    setPaperCourses(state, paperCourses) {
      state.paperCourses = paperCourses;
    },
    setAbstractPaperCourses(state, abstractPaperCourses) {
      state.abstractPaperCourses = abstractPaperCourses;
    },
    addCourse(state, course) {
      if (!state.courses) {
        state.courses = [];
      }
      let existingCourseIndex = state.courses.findIndex((c) => parseInt(c.id, 10) === parseInt(course.id, 10));
      if (existingCourseIndex !== -1) {
        state.courses[existingCourseIndex] = course;
      } else {
        state.courses.push(course);
      }
    },
    addAbstractCourse(state, abstractCourse) {
      if (!state.abstractCourses) {
        state.abstractCourses = [];
      }
      let existingAbstractCourseIndex = state.abstractCourses.findIndex(
        (c) => parseInt(c.id, 10) === parseInt(abstractCourse.id, 10)
      );
      if (existingAbstractCourseIndex !== -1) {
        state.abstractCourses[existingAbstractCourseIndex] = abstractCourse;
      } else {
        state.abstractCourses.push(abstractCourse);
      }
    },
    addPaperCourse(state, paperCourse) {
      if (!state.paperCourses) {
        state.paperCourses = [];
      }
      state.paperCourses.push(paperCourse);
    },
    setAnScoInCourses(state, sco) {
      if (state.courses) {
        let courseIndex = state.courses.findIndex((c) => {
          return parseInt(c.id, 10) === parseInt(sco.course.id, 10);
        });

        if (courseIndex !== -1 && state.courses[courseIndex].scos) {
          let scoIndex = state.courses[courseIndex].scos.findIndex((s) => {
            return parseInt(s.id, 10) === parseInt(sco.id, 10);
          });
          if (scoIndex !== -1) {
            state.courses[courseIndex].scos[scoIndex] = sco;
          }
        }
      }

      if (state.selectedCourse && parseInt(state.selectedCourse.id, 10) === parseInt(sco.course.id, 10)) {
        let scoIndex = state.selectedCourse.scos.findIndex((s) => {
          return parseInt(s.id, 10) === parseInt(sco.id, 10);
        });
        if (scoIndex !== -1) {
          state.selectedCourse.scos[scoIndex] = sco;
        }
      }
    },
    setCurrentGroup(state, group) {
      state.currentGroup = group;
    },

    setCurrentGroupSubgroupVgCounts(state, subgroups) {
      subgroups.forEach((s) => {
        let matchingSub = state.currentGroup.subgroups.find((cs) => {
          return parseInt(cs.id, 10) === parseInt(s.id, 10);
        });

        if (matchingSub) {
          matchingSub.upcomingVgCount = s.upcomingVgCount;
          matchingSub.pastVgCount = s.pastVgCount;
        }
      });
    },

    addSubgroupToCurrentGroup(state, subgroup) {
      state.currentGroup.subgroups.push(subgroup);
    },
    addVirtualGroupToCurrentGroup(state, virtualGroup) {
      state.currentGroup.virtual_groups.push(virtualGroup);
    },
    updateCurrentGroupsVirtualGroup(state, virtualGroup) {
      let idx = state.currentGroup.virtual_groups.findIndex(
        (v) => parseInt(v.id, 10) === parseInt(virtualGroup.id, 10)
      );

      if (idx !== -1) {
        state.currentGroup.virtual_groups[idx] = virtualGroup;
      }
    },
    updateCurrentSubgroupsVirtualGroup(state, virtualGroup) {
      let idx = state.currentSubgroup.virtual_groups.findIndex(
        (v) => parseInt(v.id, 10) === parseInt(virtualGroup.id, 10)
      );

      if (idx !== -1) {
        state.currentSubgroup.virtual_groups[idx] = virtualGroup;
      }

      /* also update the vgs by membership only */
      idx = state.currentSubgroup.virtual_groups_by_membership_only.findIndex(
        (v) => parseInt(v.id, 10) === parseInt(virtualGroup.id, 10)
      );

      if (idx !== -1) {
        state.currentSubgroup.virtual_groups_by_membership_only[idx] = virtualGroup;
      }
    },
    updateCurrentGroupsSubgroup(state, subgroup) {
      let idx = state.currentGroup.subgroups.findIndex((s) => parseInt(s.id, 10) === parseInt(subgroup.id, 10));

      if (idx !== -1) {
        state.currentGroup.subgroups[idx] = subgroup;
      }
    },
    addVirtualGroupToCurrentSubgroup(state, virtualGroup) {
      state.currentSubgroup.virtual_groups.push(virtualGroup);
    },
    setCurrentSubgroup(state, subgroup) {
      state.currentSubgroup = subgroup;
    },
    setCurrentVirtualGroup(state, virtualGroup) {
      state.currentVirtualGroup = virtualGroup;
    },
    setVirtualGroupHealth(state, virtualGroupHealth) {
      state.virtualGroupHealth = virtualGroupHealth;
    },
    setGroupVirtualGroupHealth(state, virtualGroupHealth) {
      state.groupVirtualGroupHealth = virtualGroupHealth;
    }
  },
  actions: {
    primeVirtualGroupHealth({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.virtualGroupHealth) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel("superAdmin")
            .getVirtualGroupHealth()
            .then((response) => {
              commit("setVirtualGroupHealth", response.data);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primeGroupVirtualGroupHealth({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.virtualGroupHealth) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel("mixedAdmin")
            .getGroupVirtualGroupHealth()
            .then((response) => {
              commit("setGroupVirtualGroupHealth", response.data);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primeCourses({ commit, state }, adminLevel) {
      return new Promise((resolve, reject) => {
        if (state.courses) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel(adminLevel)
            .getCourses()
            .then((response) => {
              commit("setCourses", response.data.courses);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primeAbstractCourses({ commit, state }, adminLevel) {
      return new Promise((resolve, reject) => {
        if (state.abstractCourses) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel(adminLevel)
            .getAbstractCourses()
            .then((response) => {
              commit("setAbstractCourses", response.data.abstractCourses);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primeAbstractPaperCourses({ commit, state }, adminLevel) {
      return new Promise((resolve, reject) => {
        if (state.abstractPaperCourses) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel(adminLevel)
            .getAbstractPaperCourses()
            .then((response) => {
              commit("setAbstractPaperCourses", response.data.abstractPaperCourses);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primePaperCourses({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.paperCourses) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel("mixedAdmin")
            .getPaperCourses()
            .then((response) => {
              commit("setPaperCourses", response.data.paperCourses);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primeSelectedCourse({ commit, state }, { courseId, adminLevel }) {
      return new Promise((resolve, reject) => {
        if (state.selectedCourse && parseInt(state.selectedCourse.id, 10) === parseInt(courseId, 10)) {
          //already in memory
          return resolve();
        }

        if (state.courses) {
          let idx = state.courses.findIndex((c) => {
            return parseInt(c.id, 10) === parseInt(courseId, 10);
          });
          if (idx !== -1) {
            state.selectedCourse = state.courses[idx];
            return resolve();
          }
        }

        ApiService.atAuthLevel(adminLevel)
          .getCourse(courseId)
          .then((response) => {
            commit("setSelectedCourse", response.data.course);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    primeSelectedAbstractCourse({ commit, state }, { abstractCourseId, adminLevel }) {
      return new Promise((resolve, reject) => {
        if (
          state.selectedAbstractCourse &&
          parseInt(state.selectedAbstractCourse.id, 10) === parseInt(abstractCourseId, 10)
        ) {
          //already in memory
          return resolve();
        }

        if (state.abstractCourses) {
          let idx = state.abstractCourses.findIndex((c) => {
            return parseInt(c.id, 10) === parseInt(abstractCourseId, 10);
          });
          if (idx !== -1) {
            state.selectedAbstractCourse = state.abstractCourses[idx];
            return resolve();
          }
        }

        ApiService.atAuthLevel(adminLevel)
          .getAbstractCourse(abstractCourseId)
          .then((response) => {
            commit("setSelectedAbstractCourse", response.data.abstractCourse);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    primeSelectedPaperCourse({ commit, state }, { paperCourseId }) {
      return new Promise((resolve, reject) => {
        if (state.selectedPaperCourse && parseInt(state.selectedPaperCourse.id, 10) === parseInt(paperCourseId, 10)) {
          //already in memory
          return resolve();
        }

        if (state.paperCourses) {
          let idx = state.paperCourses.findIndex((c) => {
            return parseInt(c.id, 10) === parseInt(paperCourseId, 10);
          });
          if (idx !== -1) {
            state.selectedPaperCourse = state.paperCourses[idx];
            return resolve();
          }
        }

        ApiService.atAuthLevel("mixedAdmin")
          .getPaperCourse(paperCourseId)
          .then((response) => {
            commit("setSelectedPaperCourse", response.data.paperCourse);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateCourse({ commit }, { course, adminLevel }) {
      return new Promise((resolve, reject) => {
        ApiService.atAuthLevel(adminLevel)
          .updateCourse(course)
          .then((response) => {
            commit("setSelectedCourse", response.data.course);
            commit("updateCourseInCourses", response.data.course);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    primeCurrentGroup({ commit, state }, { groupId }) {
      return new Promise((resolve, reject) => {
        if (state.currentGroup && parseInt(state.currentGroup.id, 10) === parseInt(groupId, 10)) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel("mixedAdmin")
            .getGroup(groupId)
            .then((response) => {
              commit("setCurrentGroup", response.data.group);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primeCurrentGroupSubgroupVgCounts({ commit, state }) {
      return new Promise((resolve, reject) => {
        ApiService.atAuthLevel("mixedAdmin")
          .getGroupSubgroupVgCounts(state.currentGroup.id)
          .then((response) => {
            commit("setCurrentGroupSubgroupVgCounts", response.data.subgroups);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    primeCurrentSubgroup({ commit, state }, { subgroupId }) {
      return new Promise((resolve, reject) => {
        if (state.currentSubgroup && parseInt(state.currentSubgroup.id, 10) === parseInt(subgroupId, 10)) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel("mixedAdmin")
            .getSubgroup(subgroupId)
            .then((response) => {
              commit("setCurrentSubgroup", response.data.subgroup);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primeCurrentVirtualGroup({ commit, state }, { virtualGroupId }) {
      return new Promise((resolve, reject) => {
        if (state.currentVirtualGroup && parseInt(state.currentVirtualGroup.id, 10) === parseInt(virtualGroupId, 10)) {
          //already in memory
          resolve();
        } else {
          ApiService.atAuthLevel("mixedAdmin")
            .getVirtualGroup(virtualGroupId)
            .then((response) => {
              commit("setCurrentVirtualGroup", response.data.virtualGroup);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    refreshCurrentGroup({ commit }, { groupId, adminLevel }) {
      return new Promise((resolve, reject) => {
        ApiService.atAuthLevel(adminLevel)
          .getGroup(groupId)
          .then((response) => {
            commit("setCurrentGroup", response.data.group);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    refreshCurrentSubgroup({ commit }, { subgroupId, adminLevel }) {
      return new Promise((resolve, reject) => {
        ApiService.atAuthLevel(adminLevel)
          .getSubgroup(subgroupId)
          .then((response) => {
            commit("setCurrentSubgroup", response.data.subgroup);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    refreshCurrentVirtualGroup({ commit }, { virtualGroupId }) {
      return new Promise((resolve, reject) => {
        ApiService.atAuthLevel("mixedAdmin")
          .getVirtualGroup(virtualGroupId)
          .then((response) => {
            commit("setCurrentVirtualGroup", response.data.virtualGroup);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    refreshExistingCurrentGroup({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.currentGroup && state.currentGroup.id) {
          ApiService.atAuthLevel("mixedAdmin")
            .getGroup(state.currentGroup.id)
            .then((response) => {
              commit("setCurrentGroup", response.data.group);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve();
        }
      });
    },
    refreshExistingCurrentSubgroup({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.currentSubgroup && state.currentSubgroup.id) {
          ApiService.atAuthLevel("mixedAdmin")
            .getSubgroup(state.currentSubgroup.id)
            .then((response) => {
              commit("setCurrentSubgroup", response.data.subgroup);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve();
        }
      });
    },
    refreshExistingCurrentVirtualGroup({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.currentVirtualGroup && state.currentVirtualGroup.id) {
          // console.log('before api in refresh existing current virtual group');
          ApiService.atAuthLevel("mixedAdmin")
            .getVirtualGroup(state.currentVirtualGroup.id)
            .then((response) => {
              commit("setCurrentVirtualGroup", response.data.virtualGroup);
              // console.log('before api resolve in refresh existing current virtual group');
              resolve();
            })
            .catch((error) => {
              // console.log('before api reject in refresh existing current virtual group');
              reject(error);
            });
        } else {
          // console.log('before else resolve in refresh existing current virtual group');
          resolve();
        }
      });
    }
  }
};
